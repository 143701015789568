import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import { FileEarmarkSpreadsheet } from 'react-bootstrap-icons';
import Flexbox from '../../../../Components/UI/Box/Flexbox';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { UserRoleType } from '../../../../types/UserRoleType';
import { exportToCsvColumnsPermitList } from '../../../../data/exportToCsvColumns';

const ExportToCsvBtn = ({ getRowForCsv, permitsSize }) => {

    const { userDetails } = useProjectDetailsContext();
    const userRole = userDetails.userRole;
    
    // projectId !== 12
    if (permitsSize < 1 || userRole !== UserRoleType.ADMIN) {
        return '';
    }

    return (
        <Flexbox justifyContent='flex-end' marginLeft='1rem'>
            <CsvDownloader columns={exportToCsvColumnsPermitList} extension=".csv" separator="," filename="permits" datas={getRowForCsv}>
                <button className="btn btn-success">יצא לאקסל <FileEarmarkSpreadsheet /></button>
            </CsvDownloader>
        </Flexbox>
    );
}

export default ExportToCsvBtn;