import React from 'react';
import InputGroup from '../../../../Components/Forms/InputGroup';
import OwnershipCarType from '../../../../types/OwnershipCarType';
import OutlineCheckBtn from '../../../../Components/Forms/check/OutlineCheckBtn';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const colStyle = { 'display': 'flex', 'flexDirection': "column", 'rowGap': '0.5rem' }

const FilesCard = ({ }) => {

    const { errors, values, touched, validateField, handleBlur, setFieldValue } = usePermitDetailsPageContext();
 
    const fileTitle = 'קבצים';
    const ownershipCarType = Number(values.ownershipCarType);
    let workStatementfileDis = values.hardCopyLeasing === '1' ? true : false;
    let personIdfileDis = values.hardCopyPersonId === '1' ? true : false;
    let carNumberfileDis = values.hardCopyLicense === '1' ? true : false;

    return (
        <FormSection title={fileTitle}>
            <div className="row">
                <div className="col-lg-6 col-xl-4" style={colStyle}>
                    <InputGroup
                        type='file'
                        label={'תעודת זהות'}
                        name={'personIdfile'}
                        isRequired={!personIdfileDis}
                        disabled={personIdfileDis}
                        handleChange={handleFile}
                        handleBlur={handleBlur}
                        errorMsg={(errors.personIdfile && touched.personIdfile) ? errors.personIdfile : undefined}
                    />
                    <OutlineCheckBtn
                        label={'תעודת זהות וספח'}
                        name='hardCopyPersonId'
                        id={'hardCopyPersonId'}
                        value={'1'}
                        isChecked={values.hardCopyPersonId && values.hardCopyPersonId === '1'}
                        onClick={handleClick}
                        onBlur={event => validateField('personIdfile')}
                        />
                </div>

                <div className="col-lg-6 col-xl-4" style={colStyle}>
                    <InputGroup
                        type='file'
                        label={'רשיון רכב'}
                        name={'carLicensefile'}
                        isRequired={!carNumberfileDis}
                        disabled={carNumberfileDis}
                        handleChange={handleFile}
                        handleBlur={handleBlur}
                        errorMsg={(errors.carLicensefile && touched.carLicensefile) ? errors.carLicensefile : undefined}
                    />
                    <OutlineCheckBtn
                        label={'רשיון רכב בתוקף'}
                        id={'hardCopyLicense'}
                        name='hardCopyLicense'
                        value={'1'}
                        isChecked={values.hardCopyLicense && values.hardCopyLicense === '1'}
                        onClick={handleClick}
                        onBlur={event => validateField('carLicensefile')}
                    />
                </div>
                {ownershipCarType === OwnershipCarType.COMPANY ? (
                    <div className='col-lg-6 col-xl-4' style={colStyle}>
                        <InputGroup
                            type='file'
                            label={'הצהרה ממקום העבודה'}
                            name={'workStatementfile'}
                            isRequired={!workStatementfileDis}
                            disabled={workStatementfileDis}
                            handleChange={handleFile}
                            handleBlur={handleBlur}
                            errorMsg={(errors.workStatementfile && touched.workStatementfile) ? errors.workStatementfile : undefined}
                        />
                        <OutlineCheckBtn
                            label={'הצהרה ממקום העבודה'}
                            id={'hardCopyLeasing'}
                            name='hardCopyLeasing'
                            value={'1'}
                            isChecked={values.hardCopyLeasing && values.hardCopyLeasing === '1'}
                            onClick={handleClick}
                            onBlur={event => validateField('workStatementfile')}
                        />
                    </div>
                ) : ''}
            </div>
        </FormSection>
    );

    function handleClick(event) {

        const isChecked = event.currentTarget.checked;
        const value = isChecked ? event.currentTarget.value : '0'
        console.log(`name: ${event.currentTarget.name}, checked: ${isChecked}, value: ${value}`)
        setFieldValue(event.currentTarget.name, value);
    }

    function handleFile(event) {
        setFieldValue(event.currentTarget.name, event.currentTarget.files[0])
    }
}

export default FilesCard